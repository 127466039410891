/* eslint-disable react/prop-types */
import moment from 'moment';
import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import {
  Row, Col, Button, Typography
} from 'antd';
import _get from 'lodash/get';
import { TextField, InputNumber } from 'components/Form';
import { reduxForm, Field, FieldArray } from 'redux-form';
import ReturnProductionIcon from 'images/icon-return-product.svg';
import MyStoreFeeIcon from 'images/icon-my-store-fee.svg';
import DeliveryIcon from 'images/icon-delivery.svg';
import { Form } from '@ant-design/compatible';
import TrashIcon from 'images/icon-trash.svg';
import helpers from 'utils/helpers';
import OrderUpdateValidate from './validate';

const ItemType = {
  NEW_SHIPPING_FEE: 'NEW_SHIPPING_FEE',
  NEW_MY_STORE_FEE: 'NEW_MY_STORE_FEE',
  RETURNED_MENU: 'RETURNED_MENU',
};

const getUpdateItemConfig = (type) => {
  const configs = {
    [ItemType.NEW_SHIPPING_FEE]: {
      icon: DeliveryIcon,
      title: '送料変更',
      description: '※変更後の送料を税込で入力してください。',
      minValue: 0,
      placeholder: '送料を変更いたしました。',
    },
    [ItemType.NEW_MY_STORE_FEE]: {
      icon: MyStoreFeeIcon,
      title: 'マイストア運営費変更',
      description:
        '※変更後のマイストア運営費　{month}月分を税込で入力してください。',
      minValue: 0,
      placeholder: 'マイストア運営費を変更いたしました。',
    },
    [ItemType.RETURNED_MENU]: {
      icon: ReturnProductionIcon,
      title: '返品・訂正',
      description: '※消費税を含めた金額を引いてください。',
      className: 'negative-menu-color',
      minValue: Number.MIN_SAFE_INTEGER,
      placeholder: 'Note',
    },
  };
  return configs[type] || {};
};

const renderUpdateItems = ({
  fields,
  myStoreFee,
  shippingFee,
  hasMyStoreFee,
  orderCreatedAt,
}) => {
  const fieldTypes = fields.map((_, index) => _get(fields.get(index), 'type'));
  const allowAddNewShippingFee = !fieldTypes.includes(
    ItemType.NEW_SHIPPING_FEE
  );
  const allowAddNewMyStoreFee = hasMyStoreFee && !fieldTypes.includes(ItemType.NEW_MY_STORE_FEE);

  return (
    <>
      {fields.map((updateItem, index) => {
        const { type } = fields.get(index);
        const itemConfig = getUpdateItemConfig(type);

        return (
          <div key={index} style={{ padding: '0px 16px' }}>
            <div className="flex items-center" style={{ marginBottom: 5 }}>
              <img
                src={itemConfig.icon}
                alt="icon"
                style={{ marginRight: 4 }}
              />
              <span className="text-title" style={{ marginRight: 8 }}>
                {itemConfig.title}
              </span>
              <Typography.Text type="secondary" className="text-secondary">
                {itemConfig.description.replace(
                  '{month}',
                  moment(orderCreatedAt).format('MM')
                )}
              </Typography.Text>
            </div>
            <Row gutter={16}>
              <Col flex={1}>
                <Field
                  name={`${updateItem}.name`}
                  component={TextField}
                  textArea
                  placeholder={itemConfig.placeholder}
                />
              </Col>
              <Col>
                <Field
                  name={`${updateItem}.value`}
                  component={InputNumber}
                  addonBefore={<div className={itemConfig.className}>￥</div>}
                  className={itemConfig.className}
                  precision={0}
                  min={itemConfig.minValue}
                  max={9999999999}
                  formatter={(value) => helpers.numberWithCommas(value)}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  placeholder="金額"
                />
              </Col>
              <Col>
                <Button
                  onClick={() => fields.remove(index)}
                  type="text"
                  icon={<img src={TrashIcon} alt="icon" />}
                />
              </Col>
            </Row>
          </div>
        );
      })}

      <div className="flex">
        <Button
          className="flex items-center"
          icon={<PlusCircleFilled style={{ fontSize: 16 }} />}
          type="text"
          style={{ color: '#1890ff' }}
          onClick={() => fields.push({ type: ItemType.RETURNED_MENU, value: 1, name: '' })}
        >
          返品・訂正
        </Button>
        {allowAddNewShippingFee && (
          <Button
            className="flex items-center"
            icon={<PlusCircleFilled style={{ fontSize: 16 }} />}
            type="text"
            style={{ color: '#1890ff' }}
            onClick={() => fields.push({
              type: ItemType.NEW_SHIPPING_FEE,
              value: shippingFee ?? 0,
              name: '',
            })}
          >
            送料訂正
          </Button>
        )}
        {allowAddNewMyStoreFee && (
          <Button
            className="flex items-center"
            icon={<PlusCircleFilled style={{ fontSize: 16 }} />}
            type="text"
            style={{ color: '#1890ff' }}
            onClick={() => fields.push({
              type: ItemType.NEW_MY_STORE_FEE,
              value: myStoreFee ?? 0,
              name: '',
            })}
          >
            マイストア運営費訂正
          </Button>
        )}
      </div>
    </>
  );
};

const OrderUpdateForm = ({
  myStoreFee,
  shippingFee,
  hasMyStoreFee,
  orderCreatedAt,
}) => (
  <Form onSubmit={() => {}} layout="horizontal" colon={false}>
    <FieldArray
      name="updateItems"
      component={renderUpdateItems}
      myStoreFee={myStoreFee}
      shippingFee={shippingFee}
      hasMyStoreFee={hasMyStoreFee}
      orderCreatedAt={orderCreatedAt}
      disabled
    />
  </Form>
);

export default reduxForm({
  form: 'orderItemUpdate',
  enableReinitialize: true,
  validate: OrderUpdateValidate,
  touchOnChange: true,
})(OrderUpdateForm);
