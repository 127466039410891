import React, { useCallback, useEffect, useState } from 'react';
import { isDirty, reset, submit } from 'redux-form';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Card, Button, Space, Modal, Typography, Spin
} from 'antd';
import { useParams } from 'react-router-dom';
import withRefresh from 'components/HOCs/withRefresh';

import { doRefresh } from 'providers/CommonProvider/actions';
import {
  getRestaurantDetailRequest,
  updateMyStoreFeeRequest,
} from 'providers/RestaurantProvider/actions';

import PageHeader from 'components/PageHeader';
import MyStoreFeeForm from './MyStoreFeeForm';

const { confirm } = Modal;

const showConfirm = (message, actionFn) => {
  confirm({
    title: message,
    okText: 'はい',
    okType: 'primary',
    cancelText: 'いいえ',
    onOk: () => {
      actionFn();
    },
  });
};

const MyStoreFee = () => {
  const dispatch = useDispatch();
  const { restaurantId } = useParams();
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );
  const dirty = useSelector((state) => isDirty('myStoreFeeForm')(state));

  const [dataLoading, setDataLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const fetchRestaurantDetail = useCallback(async () => {
    setDataLoading(true);
    try {
      await dispatch(
        getRestaurantDetailRequest({ formattedObjectId: restaurantId })
      );
    } catch (error) {
      console.error(error);
    }
    setDataLoading(false);
  }, [restaurantId, dispatch]);

  useEffect(() => {
    fetchRestaurantDetail();
  }, [fetchRestaurantDetail]);

  const handleSubmit = async ({ myStoreFee }) => {
    setActionLoading(true);
    try {
      await dispatch(
        updateMyStoreFeeRequest({
          myStoreFee,
          restaurantId: restaurantDetail?.objectId,
        })
      );
      dispatch(doRefresh({ target: 'myStoreFee' }));
    } catch (error) {
      console.error(error);
    }
    setActionLoading(false);
  };

  const handleGoBack = () => {
    dispatch(push(`/restaurants/detail/${restaurantId}`));
  };

  const breadcrumbItems = [
    { path: '/restaurants', breadcrumbName: 'レストラン' },
    { path: `/detail/${restaurantId}`, breadcrumbName: restaurantDetail?.name },
    { breadcrumbName: 'マイストア運営費' },
  ];

  if (dataLoading) {
    return (
      <div
        className="full-w flex justify-center items-center"
        style={{ height: '100%' }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <>
      <PageHeader
        breadcrumbRoutes={breadcrumbItems}
        title="マイストア運営費"
        onBack={
          dirty
            ? () => showConfirm('中断してよろしいですか？', handleGoBack)
            : handleGoBack
        }
      />
      <Card
        className="bg-transparent"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <MyStoreFeeForm
          onSubmit={handleSubmit}
          initialValues={{ myStoreFee: restaurantDetail?.myStoreFee ?? '' }}
        />
      </Card>
      {dirty && (
        <Card
          className="full-w"
          style={{
            position: 'fixed',
            bottom: 0,
            background: '#293137',
            borderRadius: 0,
          }}
          bordered={false}
          bodyStyle={{ padding: '22px 22px 22px 24px' }}
        >
          <Space
            size={8}
            className="flex justify-between"
            style={{ maxWidth: 596 }}
          >
            <Typography.Text style={{ color: '#fff' }}>
              保存されていない変更
            </Typography.Text>
            <Space size={8} className="full-w flex justify-end">
              <Button
                type="default"
                disabled={actionLoading}
                onClick={() => showConfirm('中断してよろしいですか？', () => dispatch(reset('myStoreFeeForm'))
                )}
              >
                取り消す
              </Button>
              <Button
                type="primary"
                loading={actionLoading}
                onClick={() => dispatch(submit('myStoreFeeForm'))}
              >
                保存する
              </Button>
            </Space>
          </Space>
        </Card>
      )}
    </>
  );
};

export default withRefresh('myStoreFee')(MyStoreFee);
