import {
  GET_RESTAURANT_MENU_ITEM_REQUEST,
  GET_RESTAURANT_MENU_ITEM_SUCCESS,
  GET_RESTAURANT_MENU_ITEM_ERROR,
  REMOVE_RESTAURANT_MENU_ITEM_REQUEST,
  REMOVE_RESTAURANT_MENU_ITEM_SUCCESS,
  REMOVE_RESTAURANT_MENU_ITEM_ERROR,
  ASSIGN_RESTAURANT_MENU_ITEM_REQUEST,
  ASSIGN_RESTAURANT_MENU_ITEM_SUCCESS,
  ASSIGN_RESTAURANT_MENU_ITEM_ERROR,
  SEND_RESTAURANT_PASSWORD_RESET_EMAIL,
  EXPORT_RESTAURANT_LIST_REQUEST,
  EXPORT_RESTAURANT_LIST_SUCCESS,
  EXPORT_RESTAURANT_LIST_ERROR,
  ACCEPT_ALL_RESTAURANT_REQUEST,
  ACCEPT_ALL_RESTAURANT_SUCCESS,
  ACCEPT_ALL_RESTAURANT_ERROR,
  ACCEPT_RESTAURANT_REQUEST,
  ACCEPT_RESTAURANT_SUCCESS,
  ACCEPT_RESTAURANT_ERROR,
  DECLINE_ALL_RESTAURANT_REQUEST,
  DECLINE_ALL_RESTAURANT_SUCCESS,
  DECLINE_ALL_RESTAURANT_ERROR,
  DECLINE_RESTAURANT_REQUEST,
  DECLINE_RESTAURANT_SUCCESS,
  DECLINE_RESTAURANT_ERROR,
  GET_RESTAURANT_LIST_REQUEST,
  GET_RESTAURANT_LIST_SUCCESS,
  GET_RESTAURANT_LIST_ERROR,
  GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST,
  GET_RESTAURANT_LIST_FOR_SUPPLIER_SUCCESS,
  GET_RESTAURANT_LIST_FOR_SUPPLIER_ERROR,
  GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST,
  GET_RESTAURANT_LIST_FOR_OPERATOR_SUCCESS,
  GET_RESTAURANT_LIST_FOR_OPERATOR_ERROR,
  GET_RESTAURANT_DETAIL_REQUEST,
  GET_RESTAURANT_DETAIL_SUCCESS,
  GET_RESTAURANT_DETAIL_ERROR,
  RESET_ALL_ASSIGNED_MENU_ITEM_LIST,
  DISABLE_RESTAURANT_ACCOUNT_REQUEST,
  DISABLE_RESTAURANT_ACCOUNT_SUCCESS,
  DISABLE_RESTAURANT_ACCOUNT_ERROR,
  ENABLE_RESTAURANT_ACCOUNT_REQUEST,
  ENABLE_RESTAURANT_ACCOUNT_SUCCESS,
  ENABLE_RESTAURANT_ACCOUNT_ERROR,
  UPDATE_RESTAURANT_ERROR,
  UPDATE_RESTAURANT_REQUEST,
  UPDATE_RESTAURANT_SUCCESS,
  GET_RESTAURANT_SELECT_OPTIONS_SUCCESS,
  GET_RESTAURANT_SELECT_OPTIONS_ERROR,
  GET_RESTAURANT_SELECT_OPTIONS_REQUEST,
  UPDATE_RESTAURANT_INFO_SUCCESS,
  UPDATE_RESTAURANT_INFO_ERROR,
  UPDATE_RESTAURANT_INFO_REQUEST,
  UPDATE_DELIVERY_INFO_SUCCESS,
  UPDATE_DELIVERY_INFO_ERROR,
  UPDATE_DELIVERY_INFO_REQUEST,
  UPDATE_BILLING_INFO_SUCCESS,
  UPDATE_BILLING_INFO_ERROR,
  UPDATE_BILLING_INFO_REQUEST,
  UPDATE_MY_STORE_FEE_REQUEST,
  UPDATE_MY_STORE_FEE_SUCCESS,
  UPDATE_MY_STORE_FEE_ERROR,
  REGISTER_RESTAURANT_OWNER_REQUEST,
  REGISTER_RESTAURANT_OWNER_SUCCESS,
  REGISTER_RESTAURANT_OWNER_ERROR,
  ADD_RESTAURANT_NOTE_REQUEST,
  ADD_RESTAURANT_NOTE_SUCCESS,
  ADD_RESTAURANT_NOTE_ERROR,
  DOWNGRADE_TO_BASIC_REQUEST,
  DOWNGRADE_TO_BASIC_SUCCESS,
  DOWNGRADE_TO_BASIC_ERROR,
  RETRY_MEMBERSHIP_PAYMENT_ERROR,
  RETRY_MEMBERSHIP_PAYMENT_REQUEST,
  RETRY_MEMBERSHIP_PAYMENT_SUCCESS,
  OPERATOR_CONNECT_SUPPLIER_RESTAURANT_ERROR,
  OPERATOR_CONNECT_SUPPLIER_RESTAURANT_REQUEST,
  OPERATOR_CONNECT_SUPPLIER_RESTAURANT_SUCCESS,
  UPDATE_INFO_OEM_REQUEST,
  UPDATE_INFO_OEM_SUCCESS,
  UPDATE_INFO_OEM_ERROR,
} from './constants';

export function getRestaurantMenuItemRequest(payload) {
  return {
    type: GET_RESTAURANT_MENU_ITEM_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantMenuItemSuccess(payload, meta) {
  return { type: GET_RESTAURANT_MENU_ITEM_SUCCESS, payload, meta };
}

export function getRestaurantMenuItemError(payload, meta) {
  return {
    type: GET_RESTAURANT_MENU_ITEM_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function removeRestaurantMenuItemRequest(payload) {
  return {
    type: REMOVE_RESTAURANT_MENU_ITEM_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function removeRestaurantMenuItemSuccess(payload, meta) {
  return { type: REMOVE_RESTAURANT_MENU_ITEM_SUCCESS, payload, meta };
}

export function removeRestaurantMenuItemError(payload, meta) {
  return {
    type: REMOVE_RESTAURANT_MENU_ITEM_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function assignRestaurantMenuItemRequest(payload) {
  return {
    type: ASSIGN_RESTAURANT_MENU_ITEM_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function assignRestaurantMenuItemSuccess(payload, meta) {
  return { type: ASSIGN_RESTAURANT_MENU_ITEM_SUCCESS, payload, meta };
}

export function assignRestaurantMenuItemError(payload, meta) {
  return {
    type: ASSIGN_RESTAURANT_MENU_ITEM_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function sendResetRestaurantPassword(data) {
  return { type: SEND_RESTAURANT_PASSWORD_RESET_EMAIL, data };
}

export function exportRestaurantListRequest() {
  return { type: EXPORT_RESTAURANT_LIST_REQUEST, meta: { thunk: true } };
}

export function exportRestaurantListSuccess(meta) {
  return { type: EXPORT_RESTAURANT_LIST_SUCCESS, meta };
}

export function exportRestaurantListError(meta) {
  return { type: EXPORT_RESTAURANT_LIST_ERROR, meta, error: true };
}

export function acceptAllRestaurantRequest(payload) {
  return {
    type: ACCEPT_ALL_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function acceptAllRestaurantSuccess(payload, meta) {
  return { type: ACCEPT_ALL_RESTAURANT_SUCCESS, payload, meta };
}

export function acceptAllRestaurantError(payload, meta) {
  return {
    type: ACCEPT_ALL_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function acceptRestaurantRequest(payload) {
  return {
    type: ACCEPT_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function acceptRestaurantSuccess(payload, meta) {
  return { type: ACCEPT_RESTAURANT_SUCCESS, payload, meta };
}

export function acceptRestaurantError(payload, meta) {
  return {
    type: ACCEPT_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function declineAllRestaurantRequest(payload) {
  return {
    type: DECLINE_ALL_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function declineAllRestaurantSuccess(payload, meta) {
  return { type: DECLINE_ALL_RESTAURANT_SUCCESS, payload, meta };
}

export function declineAllRestaurantError(payload, meta) {
  return {
    type: DECLINE_ALL_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function declineRestaurantRequest(payload) {
  return {
    type: DECLINE_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function declineRestaurantSuccess(payload, meta) {
  return { type: DECLINE_RESTAURANT_SUCCESS, payload, meta };
}

export function declineRestaurantError(payload, meta) {
  return {
    type: DECLINE_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantListRequest(payload) {
  return {
    type: GET_RESTAURANT_LIST_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantListSuccess(payload, meta) {
  return { type: GET_RESTAURANT_LIST_SUCCESS, payload, meta };
}

export function getRestaurantListError(payload, meta) {
  return {
    type: GET_RESTAURANT_LIST_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantListForSupplierRequest(payload) {
  return {
    type: GET_RESTAURANT_LIST_FOR_SUPPLIER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantListForSupplierSuccess(payload, meta) {
  return { type: GET_RESTAURANT_LIST_FOR_SUPPLIER_SUCCESS, payload, meta };
}

export function getRestaurantListForSupplierError(payload, meta) {
  return {
    type: GET_RESTAURANT_LIST_FOR_SUPPLIER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantListForOperatorRequest(payload) {
  return {
    type: GET_RESTAURANT_LIST_FOR_OPERATOR_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantListForOperatorSuccess(payload, meta) {
  return { type: GET_RESTAURANT_LIST_FOR_OPERATOR_SUCCESS, payload, meta };
}

export function getRestaurantListForOperatorError(payload, meta) {
  return {
    type: GET_RESTAURANT_LIST_FOR_OPERATOR_ERROR,
    payload,
    meta,
    error: true,
  };
}

export const getRestaurantDetailRequest = (payload) => ({
  type: GET_RESTAURANT_DETAIL_REQUEST,
  payload,
  meta: { thunk: true },
});

export const getRestaurantDetailSuccess = (payload, meta) => ({
  type: GET_RESTAURANT_DETAIL_SUCCESS,
  payload,
  meta,
});

export const getRestaurantDetailError = (payload, meta) => ({
  type: GET_RESTAURANT_DETAIL_ERROR,
  payload,
  meta,
  error: true,
});

export const resetAllAssignedMenuItemList = () => ({
  type: RESET_ALL_ASSIGNED_MENU_ITEM_LIST,
});

export function disableRestaurantAccountRequest(payload) {
  return {
    type: DISABLE_RESTAURANT_ACCOUNT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function disableRestaurantAccountSuccess(payload, meta) {
  return { type: DISABLE_RESTAURANT_ACCOUNT_SUCCESS, payload, meta };
}

export function disableRestaurantAccountError(payload, meta) {
  return {
    type: DISABLE_RESTAURANT_ACCOUNT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function enableRestaurantAccountRequest(payload) {
  return {
    type: ENABLE_RESTAURANT_ACCOUNT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function enableRestaurantAccountSuccess(payload, meta) {
  return { type: ENABLE_RESTAURANT_ACCOUNT_SUCCESS, payload, meta };
}

export function enableRestaurantAccountError(payload, meta) {
  return {
    type: ENABLE_RESTAURANT_ACCOUNT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateRestaurantSuccess(payload, meta) {
  return { type: UPDATE_RESTAURANT_SUCCESS, payload, meta };
}

export function updateRestaurantRequest(payload) {
  return {
    type: UPDATE_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateRestaurantError(payload, meta) {
  return {
    type: UPDATE_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function getRestaurantSelectOptionsRequest(payload) {
  return {
    type: GET_RESTAURANT_SELECT_OPTIONS_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function getRestaurantSelectOptionsSuccess(payload, meta) {
  return { type: GET_RESTAURANT_SELECT_OPTIONS_SUCCESS, payload, meta };
}

export function getRestaurantSelectOptionsError(payload, meta) {
  return {
    type: GET_RESTAURANT_SELECT_OPTIONS_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateRestaurantInfoRequest(payload) {
  return {
    type: UPDATE_RESTAURANT_INFO_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateRestaurantInfoSuccess(payload, meta) {
  return { type: UPDATE_RESTAURANT_INFO_SUCCESS, payload, meta };
}

export function updateRestaurantInfoError(payload, meta) {
  return {
    type: UPDATE_RESTAURANT_INFO_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateDeliveryInfoRequest(payload) {
  return {
    type: UPDATE_DELIVERY_INFO_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateDeliveryInfoSuccess(payload, meta) {
  return { type: UPDATE_DELIVERY_INFO_SUCCESS, payload, meta };
}

export function updateDeliveryInfoError(payload, meta) {
  return {
    type: UPDATE_DELIVERY_INFO_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateBillingInfoRequest(payload) {
  return {
    type: UPDATE_BILLING_INFO_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateBillingInfoSuccess(payload, meta) {
  return { type: UPDATE_BILLING_INFO_SUCCESS, payload, meta };
}

export function updateBillingInfoError(payload, meta) {
  return {
    type: UPDATE_BILLING_INFO_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateMyStoreFeeRequest(payload) {
  return {
    type: UPDATE_MY_STORE_FEE_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateMyStoreFeeSuccess(payload, meta) {
  return { type: UPDATE_MY_STORE_FEE_SUCCESS, payload, meta };
}

export function updateMyStoreFeeError(payload, meta) {
  return {
    type: UPDATE_MY_STORE_FEE_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function registerRestaurantOwnerRequest(payload) {
  return {
    type: REGISTER_RESTAURANT_OWNER_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function registerRestaurantOwnerSuccess(payload, meta) {
  return { type: REGISTER_RESTAURANT_OWNER_SUCCESS, payload, meta };
}

export function registerRestaurantOwnerError(payload, meta) {
  return {
    type: REGISTER_RESTAURANT_OWNER_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function addRestaurantNoteRequest(payload) {
  return {
    type: ADD_RESTAURANT_NOTE_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function addRestaurantNoteSuccess(payload, meta) {
  return { type: ADD_RESTAURANT_NOTE_SUCCESS, payload, meta };
}

export function addRestaurantNoteError(payload, meta) {
  return {
    type: ADD_RESTAURANT_NOTE_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function downgradeToBasicRequest(payload) {
  return {
    type: DOWNGRADE_TO_BASIC_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function downgradeToBasicSuccess(payload, meta) {
  return { type: DOWNGRADE_TO_BASIC_SUCCESS, payload, meta };
}

export function downgradeToBasicError(payload, meta) {
  return {
    type: DOWNGRADE_TO_BASIC_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function retryMembershipPaymentRequest(payload) {
  return {
    type: RETRY_MEMBERSHIP_PAYMENT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function retryMembershipPaymentSuccess(payload, meta) {
  return { type: RETRY_MEMBERSHIP_PAYMENT_SUCCESS, payload, meta };
}

export function retryMembershipPaymentError(payload, meta) {
  return {
    type: RETRY_MEMBERSHIP_PAYMENT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function operatorConnectSupplierRestaurantRequest(payload) {
  return {
    type: OPERATOR_CONNECT_SUPPLIER_RESTAURANT_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function operatorConnectSupplierRestaurantSuccess(payload, meta) {
  return { type: OPERATOR_CONNECT_SUPPLIER_RESTAURANT_SUCCESS, payload, meta };
}

export function operatorConnectSupplierRestaurantError(payload, meta) {
  return {
    type: OPERATOR_CONNECT_SUPPLIER_RESTAURANT_ERROR,
    payload,
    meta,
    error: true,
  };
}

export function updateInfoOEMRequest(payload) {
  return {
    type: UPDATE_INFO_OEM_REQUEST,
    payload,
    meta: { thunk: true },
  };
}

export function updateInfoOEMSuccess(payload, meta) {
  return { type: UPDATE_INFO_OEM_SUCCESS, payload, meta };
}

export function updateInfoOEMError(payload, meta) {
  return {
    type: UPDATE_INFO_OEM_ERROR,
    payload,
    meta,
    error: true,
  };
}
