import React from 'react';
import { Redirect } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { USER_ROLE } from 'utils/constants';
import RestaurantListForSupplier from 'containers/AdminPage/Content/Restaurants/Supplier_List';
import RestaurantListForOperator from 'containers/AdminPage/Content/Restaurants/Operator_List';
import RestaurantListForBrandOwner from 'containers/AdminPage/Content/Restaurants/BrandOwner_List';
import RestaurantListForRestaurantOwner from 'containers/AdminPage/Content/Restaurants/RestaurantOwner_List';
import AllProductList from 'containers/AdminPage/Content/Products/List';
import ProductCreate from 'containers/AdminPage/Content/Products/Create';
import AirLogiImport from 'containers/AdminPage/Content/Orders/AirLogiImport';
import OrderListForOperatorAndSupplier from 'containers/AdminPage/Content/Orders/OperatorAndSupplier_List';
import OrderListForBrandOwner from 'containers/AdminPage/Content/Orders/BrandOwner_List';
import OrderDetailForOperatorAndSupplier from 'containers/AdminPage/Content/Orders/OperatorAndSupplier_Detail';
import OrderDetailForSupplier from 'containers/AdminPage/Content/Orders/Supplier_Detail';
import OrderDetailForOperator from 'containers/AdminPage/Content/Orders/Operator_Detail';
import OrderDetailForBrandOwner from 'containers/AdminPage/Content/Orders/BrandOwner_Detail';
import ManufacturerList from 'containers/AdminPage/Content/Manufacturers/List';
import PointHistory from 'containers/AdminPage/Content/PointHistory';
import ManufacturerDetail from 'containers/AdminPage/Content/Manufacturers/Detail';
import ManufacturerCreate from 'containers/AdminPage/Content/Manufacturers/Create';
import Announcements from 'containers/AdminPage/Content/Announcements/List';
import AnnouncementCreate from 'containers/AdminPage/Content/Announcements/Create';
import AnnouncementDetail from 'containers/AdminPage/Content/Announcements/Detail';
import BrandListForOperator from 'containers/AdminPage/Content/Brands/Operator_List';
import BrandListForSupplier from 'containers/AdminPage/Content/Brands/Supplier_List';
import BrandCreate from 'containers/AdminPage/Content/Brands/Create';
import Settings from 'containers/AdminPage/Content/SettingsPage';
import ProfileForSupplier from 'containers/AdminPage/Content/SettingsPage/Supplier_Profile';
import ProfileForBrandOwner from 'containers/AdminPage/Content/SettingsPage/BrandOwner_Profile';
import ProfileForRestaurantOwner from 'containers/AdminPage/Content/SettingsPage/RestaurantOwner_Profile';
import ChangePassword from 'containers/AdminPage/Content/SettingsPage/ChangePassword';
import ShippingFee from 'containers/AdminPage/Content/SettingsPage/ShippingFee';
import Holiday from 'containers/AdminPage/Content/SettingsPage/Holiday';
import EmailNotification from 'containers/AdminPage/Content/SettingsPage/EmailNotification';
import ChangeEmail from 'containers/AdminPage/Content/SettingsPage/ChangeEmail';
import SupplierList from 'containers/AdminPage/Content/Suppliers/List';
import SupplierDetailForOperator from 'containers/AdminPage/Content/Suppliers/Operator_Detail';
import SupplierDetailForBrandOwner from 'containers/AdminPage/Content/Suppliers/BrandOwner_Detail';
import Invoice from 'containers/AdminPage/Content/SettingsPage/Invoice';
import BrandDetailForOperatorAndBrandOwner from 'containers/AdminPage/Content/Brands/OperatorAndBrandOwner_Detail';
import BrandDetailForSupplier from 'containers/AdminPage/Content/Brands/Supplier_Detail';
import RestaurantDetailForOperator from 'containers/AdminPage/Content/Restaurants/Operator_Detail';
import RestaurantDetailForRestaurantOwner from 'containers/AdminPage/Content/Restaurants/RestaurantOwner_Detail';
import RestaurantDetailForSupplier from 'containers/AdminPage/Content/Restaurants/Supplier_Detail/index';
import GuestInformationList from 'containers/AdminPage/Content/GuestInformation/List';
import RestaurantProfile from 'containers/AdminPage/Content/Restaurants/Profile';
import RestaurantBilling from 'containers/AdminPage/Content/Restaurants/Billing';
import RestaurantMyStoreFee from 'containers/AdminPage/Content/Restaurants/MyStoreFee';
import RestaurantDelivery from 'containers/AdminPage/Content/Restaurants/Delivery';
import RestaurantDetailForBrandOwner from 'containers/AdminPage/Content/Restaurants/BrandOwner_Detail';
import RestaurantOwnerList from 'containers/AdminPage/Content/RestaurantOwner/List';
import RestaurantOwnerDetail from 'containers/AdminPage/Content/RestaurantOwner/Detail';
import ProductDetailForSupplier from 'containers/AdminPage/Content/Products/Supplier_Detail';
import ProductDetailForBrandOwner from 'containers/AdminPage/Content/Products/BrandOwner_Detail';
import AirLogiHistory from 'containers/AdminPage/Content/Orders/AirLogiHistory';
import AirLogiImportHistoryDetail from 'containers/AdminPage/Content/Orders/AirLogiHistoryDetail';
import BrandOwnerDetail from '../Content/Brands/OperatorAndBrandOwner_Detail/BrandOwnerDetail';
import CollectionsList from '../Content/Collections/List';
import CollectionsCreate from '../Content/Collections/Create';
import CollectionsDetail from '../Content/Collections/Detail';
import TagList from '../Content/Tags/List';
import TagCreate from '../Content/Tags/Create';
import TagDetail from '../Content/Tags/Detail';
import RankingList from '../Content/Ranking/List';
import RankingDetail from '../Content/Ranking/Detail';
import BannerList from '../Content/Banner/List';
import InAppMessage from '../Content/InAppMessage/List';
import InAppMessageDetail from '../Content/InAppMessage/Detail';
import InAppMessageCreate from '../Content/InAppMessage/Create';
import RestaurantGroupList from '../Content/RestaurantGroup/List';

const defaultRouteByRole = {
  [USER_ROLE.OPERATOR]: '/orders',
  [USER_ROLE.SUPPLIER]: '/orders',
  [USER_ROLE.BRAND_OWNER]: '/orders',
  [USER_ROLE.RESTAURANT_OWNER]: '/orders',
};

const RedirectToDefaultRoute = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );

  return <Redirect to={defaultRouteByRole[currentUser.role]} />;
};

const RenderRestaurantListByRole = () => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const restaurantListPerRole = {
    [USER_ROLE.OPERATOR]: <RestaurantListForOperator />,
    [USER_ROLE.SUPPLIER]: <RestaurantListForSupplier />,
    [USER_ROLE.BRAND_OWNER]: <RestaurantListForBrandOwner />,
    [USER_ROLE.RESTAURANT_OWNER]: <RestaurantListForRestaurantOwner />,
  };

  return restaurantListPerRole[currentUser?.role];
};

const renderBrandDetailByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const brandDetailByRole = {
    [USER_ROLE.OPERATOR]: <BrandDetailForOperatorAndBrandOwner {...props} />,
    [USER_ROLE.SUPPLIER]: <BrandDetailForSupplier {...props} />,
    [USER_ROLE.BRAND_OWNER]: <BrandDetailForOperatorAndBrandOwner {...props} />,
  };

  return brandDetailByRole[currentUser?.role];
};

const renderRestaurantDetailByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const restaurantDetailByRole = {
    [USER_ROLE.OPERATOR]: <RestaurantDetailForOperator {...props} />,
    [USER_ROLE.RESTAURANT_OWNER]: (
      <RestaurantDetailForRestaurantOwner {...props} />
    ),
    [USER_ROLE.SUPPLIER]: <RestaurantDetailForSupplier {...props} />,
    [USER_ROLE.BRAND_OWNER]: <RestaurantDetailForBrandOwner {...props} />,
  };

  return restaurantDetailByRole[currentUser?.role];
};

const renderProductDetailByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const restaurantDetailByRole = {
    [USER_ROLE.OPERATOR]: <ProductDetailForSupplier {...props} />,
    [USER_ROLE.SUPPLIER]: <ProductDetailForSupplier {...props} />,
    [USER_ROLE.BRAND_OWNER]: <ProductDetailForBrandOwner {...props} />,
  };

  return restaurantDetailByRole[currentUser?.role];
};

const renderProfileByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const profileByRole = {
    [USER_ROLE.SUPPLIER]: <ProfileForSupplier {...props} />,
    [USER_ROLE.RESTAURANT_OWNER]: <ProfileForRestaurantOwner {...props} />,
    [USER_ROLE.BRAND_OWNER]: <ProfileForBrandOwner {...props} />,
  };

  return profileByRole[currentUser?.role];
};

const renderBrandListByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const brandListByRole = {
    [USER_ROLE.OPERATOR]: <BrandListForOperator {...props} />,
    [USER_ROLE.SUPPLIER]: <BrandListForSupplier {...props} />,
    [USER_ROLE.BRAND_OWNER]: <BrandListForSupplier {...props} />,
  };

  return brandListByRole[currentUser?.role];
};
const renderSupplierDetailByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const brandListByRole = {
    [USER_ROLE.OPERATOR]: <SupplierDetailForOperator {...props} />,
    [USER_ROLE.BRAND_OWNER]: <SupplierDetailForBrandOwner {...props} />,
  };

  return brandListByRole[currentUser?.role];
};

const renderOrderListByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const orderListByRole = {
    [USER_ROLE.OPERATOR]: <OrderListForOperatorAndSupplier {...props} />,
    [USER_ROLE.SUPPLIER]: <OrderListForOperatorAndSupplier {...props} />,
    [USER_ROLE.RESTAURANT_OWNER]: (
      <OrderListForOperatorAndSupplier {...props} />
    ),
    [USER_ROLE.BRAND_OWNER]: <OrderListForBrandOwner {...props} />,
  };

  return orderListByRole[currentUser?.role];
};
const renderOrderDetailByRole = (props) => {
  const currentUser = useSelector(
    (state) => state.authProvider.currentUser,
    shallowEqual
  );
  const orderDetailByRole = {
    [USER_ROLE.OPERATOR]: <OrderDetailForOperator {...props} />,
    [USER_ROLE.RESTAURANT_OWNER]: (
      <OrderDetailForOperatorAndSupplier {...props} />
    ),
    [USER_ROLE.SUPPLIER]: <OrderDetailForSupplier {...props} />,
    [USER_ROLE.BRAND_OWNER]: <OrderDetailForBrandOwner {...props} />,
  };

  return orderDetailByRole[currentUser?.role];
};

const indexRoutes = [
  {
    path: '/collections/detail/:collectionId',
    component: CollectionsDetail,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/collections/create',
    component: CollectionsCreate,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/collections',
    component: CollectionsList,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/restaurant-owner/detail/:restaurantOwnerId',
    component: RestaurantOwnerDetail,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/restaurant-owner',
    component: RestaurantOwnerList,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/suppliers/detail/:supplierId',
    component: renderSupplierDetailByRole,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.BRAND_OWNER],
  },
  {
    path: '/suppliers',
    component: SupplierList,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.BRAND_OWNER],
  },
  {
    path: '/settings/notification',
    component: EmailNotification,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/settings/shippingFee',
    component: ShippingFee,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/settings/invoice',
    component: Invoice,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/settings/changePassword',
    component: ChangePassword,
    roles: [
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/settings/profile',
    component: renderProfileByRole,
    roles: [
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/settings/holiday',
    component: Holiday,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/settings/changeEmail',
    component: ChangeEmail,
    roles: [
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/settings',
    component: Settings,
    roles: [
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/brands/create',
    component: BrandCreate,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/brands/brand-owners/detail/:brandOwnerId',
    component: BrandOwnerDetail,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/brands/detail/:brandId',
    component: renderBrandDetailByRole,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.SUPPLIER, USER_ROLE.BRAND_OWNER],
  },
  {
    path: '/brands',
    component: renderBrandListByRole,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.SUPPLIER, USER_ROLE.BRAND_OWNER],
  },
  {
    path: '/announcements/create',
    component: AnnouncementCreate,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/announcements/detail/:announcementId',
    component: AnnouncementDetail,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/announcements',
    component: Announcements,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/in-app-messages/create',
    component: InAppMessageCreate,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/in-app-messages/detail/:inAppMessageId',
    component: InAppMessageDetail,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/in-app-messages',
    component: InAppMessage,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/banners',
    component: BannerList,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/manufacturers/create',
    component: ManufacturerCreate,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/manufacturers/detail/:manufacturerId',
    component: ManufacturerDetail,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.SUPPLIER],
  },
  {
    path: '/manufacturers',
    component: ManufacturerList,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.SUPPLIER],
  },
  {
    path: '/point-history',
    component: PointHistory,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/orders/airLogi-import/history/:importHistoryId',
    component: AirLogiImportHistoryDetail,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/orders/airLogi-import/history',
    component: AirLogiHistory,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/orders/airLogi-import',
    component: AirLogiImport,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/orders/detail/:orderId',
    component: renderOrderDetailByRole,
    roles: [
      USER_ROLE.OPERATOR,
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/orders',
    component: renderOrderListByRole,
    roles: [
      USER_ROLE.OPERATOR,
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/rankings/detail/:rankingId',
    component: RankingDetail,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/rankings',
    component: RankingList,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/tags/create',
    component: TagCreate,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/tags/detail/:tagId',
    component: TagDetail,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/tags/',
    component: TagList,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/products/create',
    component: ProductCreate,
    roles: [USER_ROLE.SUPPLIER],
  },
  {
    path: '/products/detail/:menuId',
    component: renderProductDetailByRole,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.SUPPLIER, USER_ROLE.BRAND_OWNER],
  },
  {
    path: '/products/',
    component: AllProductList,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.SUPPLIER, USER_ROLE.BRAND_OWNER],
  },
  {
    path: '/restaurants/detail/:restaurantId/profile',
    component: RestaurantProfile,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/restaurants/detail/:restaurantId/delivery',
    component: RestaurantDelivery,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/restaurants/detail/:restaurantId/billing',
    component: RestaurantBilling,
    roles: [USER_ROLE.OPERATOR, USER_ROLE.RESTAURANT_OWNER],
  },

  {
    path: '/restaurants/detail/:restaurantId/my-store-fee',
    component: RestaurantMyStoreFee,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/restaurants/detail/:restaurantId',
    component: renderRestaurantDetailByRole,
    roles: [
      USER_ROLE.OPERATOR,
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/restaurants',
    component: RenderRestaurantListByRole,
    roles: [
      USER_ROLE.OPERATOR,
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
  {
    path: '/restaurant-group',
    component: RestaurantGroupList,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/guest-information',
    component: GuestInformationList,
    roles: [USER_ROLE.OPERATOR],
  },
  {
    path: '/',
    component: RedirectToDefaultRoute,
    roles: [
      USER_ROLE.OPERATOR,
      USER_ROLE.SUPPLIER,
      USER_ROLE.BRAND_OWNER,
      USER_ROLE.RESTAURANT_OWNER,
    ],
  },
];

export default indexRoutes;
