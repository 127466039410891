import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import { Card } from 'antd';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { InputNumber } from 'components/Form';
import Helpers from 'utils/helpers';

const MyStoreFeeForm = ({ handleSubmit }) => (
  <Form
    labelCol={{ span: 6 }}
    colon={false}
    layout="horizontal"
    onSubmit={handleSubmit}
  >
    <Card
      style={{ maxWidth: 596 }}
      headStyle={{ fontSize: 20, fontWeight: 'bold' }}
      className="highlight-card"
      title="マイストア運営費"
    >
      <Field
        label="マイストア運営費"
        description="任意"
        name="myStoreFee"
        component={InputNumber}
        min={0}
        precision={0}
        formatter={Helpers.numberWithCommas}
        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
        addonBefore="¥"
      />
    </Card>
  </Form>
);

MyStoreFeeForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'myStoreFeeForm',
  touchOnChange: true,
})(MyStoreFeeForm);
