import moment from 'moment';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _some from 'lodash/some';
import React, { useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import {
  Row,
  Col,
  Tag,
  Card,
  Typography,
  Space,
  Alert,
  Descriptions,
} from 'antd';
import { useParams } from 'react-router-dom';

import Helpers from 'utils/helpers';

import CouponIcon from 'images/coupon.svg';

import withRefresh from 'components/HOCs/withRefresh';
import Table from 'components/Table';
import PageHeader from 'components/PageHeader';
import { getOrderDetailRequest } from 'providers/OrderProvider/actions';

import DeliveryInfo from './DeliveryInfo';
import OrderMessage from './OrderMessage';
import Columns from './Columns';
import SupplierInfo from './SupplierInfo';
import NavigationButton from './NavigationButton';
import TrackingDetail from './TrackingDetail';

import './style.scss';

const OrderDetail = () => {
  const orderDetail = useSelector(
    (state) => state.orderProvider.orderDetails,
    shallowEqual
  );

  const updateItems = _get(orderDetail, 'updateItems', []);

  const orderList = useSelector(
    (state) => state.orderProvider.orderList,
    shallowEqual
  );
  const loading = useSelector(
    (state) => state.orderProvider.loading,
    shallowEqual
  );
  const dispatch = useDispatch();

  const { orderId } = useParams();

  const orderStatus = _get(orderDetail, 'status');

  const breadcrumbItems = [
    {
      path: '/orders',
      breadcrumbName: '注文一覧',
    },
    {
      breadcrumbName: `#${orderId}`,
    },
  ];

  const getOrderDetail = useCallback(async () => {
    dispatch(getOrderDetailRequest({ id: orderId }));
  }, [orderId]);

  useEffect(() => {
    getOrderDetail();
  }, [getOrderDetail]);

  const renderOrderList = () => {
    const data = [
      ..._map(orderDetail?.items, (i) => ({
        ...i,
        itemType: 'ORDER_ITEM',
      })),
      ..._map(updateItems, (i) => ({
        ...i,
        itemType: 'UPDATE_ITEM',
      })),
    ];

    if (orderDetail?.discountPoint) {
      data.push({
        itemType: 'DISCOUNT_POINT_ITEM',
        name: `${Helpers.numberWithCommas(
          orderDetail?.discountPoint
        )}ポイント利用`,
        value: -orderDetail?.discountPoint,
      });
    }

    const columns = [
      Columns.menuName,
      Columns.price,
      Columns.quantity,
      Columns.taxRate,
      Columns.totalPriceAfterTax,
    ];

    return (
      <Table
        rowKey="objectId"
        columns={columns}
        data={data}
        total={data.length}
        loading={loading}
        pagination={null}
        scroll={{ x: 540 }}
      />
    );
  };

  const OrderHeader = () => (
    <Space direction="vertical" size={0} className="order-header" align="start">
      <Typography.Text>Order #{orderId}</Typography.Text>
    </Space>
  );

  return (
    <div id="order-detail-container">
      <PageHeader
        title={<OrderHeader />}
        breadcrumbRoutes={breadcrumbItems}
        onBack={() => dispatch(goBack())}
        extra={NavigationButton(orderId, dispatch, orderList)}
      />
      <Card
        className="bg-transparent"
        bordered={false}
        bodyStyle={{ paddingTop: 0 }}
      >
        <Row gutter={[16, 16]}>
          <Col xl={{ span: 15 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card
                  className="highlight-card padding-card"
                  bordered={false}
                  title="注文商品"
                  headStyle={{
                    fontSize: '20px',
                    color: '#000',
                    padding: '0 16px',
                    borderBottom: '2px solid #e8e8e8',
                  }}
                >
                  {renderOrderList()}
                  <div className="flex justify-between" style={{ padding: 16 }}>
                    <div id="coupon-code">
                      {_get(orderDetail, 'couponCode') && (
                        <Alert
                          style={{
                            width: 'max-content',
                            maxWidth: '100%',
                            marginRight: '12px',
                          }}
                          icon={(
                            <img
                              alt="note-icon"
                              src={CouponIcon}
                              style={{ fill: 'green' }}
                            />
                          )}
                          showIcon
                          message={(
                            <div style={{ whiteSpace: 'pre-line' }}>
                              {_get(orderDetail, 'couponCode')}
                            </div>
                          )}
                          type="warning"
                        />
                      )}
                    </div>
                    <div id="order-summary">
                      <Descriptions column={1} bordered>
                        <Descriptions.Item label="小計（税込）">
                          {Helpers.priceFormat(
                            orderDetail?.total
                              - (orderDetail?.shippingFee ?? 0)
                              - (orderDetail?.myStoreFee ?? 0)
                          )}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={`送料
                                ${orderDetail?.shippingFee ? '（税込）' : ''}`}
                        >
                          {orderDetail?.shippingFee === 0 ? (
                            <Tag
                              style={{ float: 'right', margin: 0 }}
                              color="blue"
                            >
                              無料
                            </Tag>
                          ) : (
                            Helpers.priceFormat(orderDetail?.shippingFee)
                          )}
                        </Descriptions.Item>
                        {orderDetail?.myStoreFeeCollected && (_some(updateItems, { type: 'NEW_MY_STORE_FEE' }) || orderDetail?.myStoreFee > 0) && (
                          <Descriptions.Item
                            label={`マイストア運営費
                                ${moment(orderDetail?.createdAt).format('MM')}
                                月分（税込）`}
                          >
                            {Helpers.priceFormat(orderDetail?.myStoreFee)}
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item
                          label="総計（税込）"
                          style={{
                            fontWeight: 700,
                            padding: '16px 0 !important',
                          }}
                        >
                          {Helpers.priceFormat(orderDetail?.total)}
                        </Descriptions.Item>
                      </Descriptions>
                    </div>
                  </div>
                </Card>
              </Col>
              <TrackingDetail orderDetail={orderDetail} />
            </Row>
          </Col>
          <Col xl={{ span: 9 }} md={{ span: 24 }} xs={{ span: 24 }}>
            <Space direction="vertical" size={16} className="full-w">
              <DeliveryInfo orderDetail={orderDetail} />
              <SupplierInfo orderDetail={orderDetail} />
              <OrderMessage orderId={orderId} orderStatus={orderStatus} />
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default withRefresh('orderDetail')(OrderDetail);
