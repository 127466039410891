import { ERROR_MESSAGE } from 'utils/constants';

const validateUpdateItem = (
  updateItem,
  totalReturnedMenuPrice,
  totalItemsPriceAfterTax
) => {
  const updateItemError = {};

  if (
    ['RETURNED_MENU', 'NEW_SHIPPING_FEE'].includes(updateItem.type)
    && !updateItem.name?.trim()
  ) {
    updateItemError.name = ERROR_MESSAGE.REQUIRED;
  }

  if (
    ['NEW_SHIPPING_FEE', 'NEW_MY_STORE_FEE'].includes(updateItem.type)
    && (updateItem.value == null || updateItem.value < 0)
  ) {
    updateItemError.value = ERROR_MESSAGE.INVALID;
  }

  if (updateItem.type === 'RETURNED_MENU') {
    if (updateItem.value == null) {
      updateItemError.value = ERROR_MESSAGE.REQUIRED;
    }
    if (
      updateItem.value === 0
      || totalReturnedMenuPrice + totalItemsPriceAfterTax < 1
    ) {
      updateItemError.value = ERROR_MESSAGE.INVALID;
    }
  }

  return updateItemError;
};

const validate = (values, props) => {
  const errors = {};

  if (values.updateItems && values.updateItems.length) {
    const totalReturnedMenuPrice = values.updateItems.reduce(
      (acc, item) => (item.type === 'RETURNED_MENU' ? acc + item.value : acc),
      0
    );

    const updateItemsArrayErrors = values.updateItems.map((updateItem) => validateUpdateItem(
      updateItem,
      totalReturnedMenuPrice,
      props.totalItemsPriceAfterTax
    )
    );

    if (updateItemsArrayErrors.some((error) => Object.keys(error).length > 0)) {
      errors.updateItems = updateItemsArrayErrors;
    }
  }

  return errors;
};

export default validate;
