import React from 'react';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import {
  Space, Typography, Avatar, Tooltip
} from 'antd';

import DefaultProductImage from 'images/defaultProduct.svg';
import RedReturnProductIcon from 'images/icon-return-product-red.svg';
import MyStoreFeeIcon from 'images/icon-my-store-fee.svg';
import DeliveryIcon from 'images/icon-delivery.svg';
import MarketPriceIcon from 'images/icon-market-price.svg';
import PackagePriceIcon from 'images/icon-package-price.svg';
import DefaultBrandImage from 'images/defaultBrand.svg';
import PointIcon from 'images/icon-point.svg';

import Helpers from 'utils/helpers';

const Columns = {};

const updateItemData = {
  RETURNED_MENU: {
    title: '返品・訂正',
    icon: RedReturnProductIcon,
  },
  NEW_SHIPPING_FEE: {
    title: '送料変更',
    icon: DeliveryIcon,
  },
  NEW_MY_STORE_FEE: {
    title: 'マイストア運営費変更',
    icon: MyStoreFeeIcon,
  },
};

const renderOrderItemName = (record) => (
  <Space className="flex items-center">
    <Avatar
      alt="name"
      src={_get(record, 'image', DefaultProductImage)}
      shape="square"
    />
    <Space direction="vertical" size={0}>
      <Space>
        <Typography.Text style={{ wordBreak: 'break-all' }}>
          {_get(record, 'name')}
        </Typography.Text>
        {record.isMarketPrice && (
          <Tooltip placement="topLeft" title="時価">
            <img src={MarketPriceIcon} alt="market-price-icon" />
          </Tooltip>
        )}
        {record.isIndefinitePrice && (
          <Tooltip placement="topLeft" title="不定貫">
            <img src={PackagePriceIcon} alt="indefinite-price-icon" />
          </Tooltip>
        )}
      </Space>
      <Typography.Text type="secondary" style={{ fontSize: 12 }}>
        {_get(record, 'formattedUnit')}
      </Typography.Text>
    </Space>
  </Space>
);

const renderUpdateItemName = (record) => {
  const returnedMenuStyle = record.type === 'RETURNED_MENU' ? { color: '#CF1421' } : {};

  return (
    <Space className="flex items-center">
      <Avatar
        size={32}
        shape="square"
        className="flex items-center justify-center"
        style={{
          backgroundColor:
            record?.type === 'RETURNED_MENU'
              ? '#CF142110'
              : 'rgba(0, 0, 0, 0.05)',
        }}
        src={(
          <img
            src={updateItemData[record?.type]?.icon}
            alt="icon"
            style={{ width: 16, height: 16 }}
          />
        )}
      />
      <Space direction="vertical" size={0}>
        <Space>
          <Typography.Text
            style={{ wordBreak: 'break-all', ...returnedMenuStyle }}
          >
            {updateItemData[record.type]?.title}
          </Typography.Text>
        </Space>
        <Typography.Text
          type="secondary"
          style={{ fontSize: 12, ...returnedMenuStyle }}
        >
          {_get(record, 'name')}
        </Typography.Text>
      </Space>
    </Space>
  );
};

const renderPointUsageItemName = (record) => (
  <Space className="flex items-center">
    <Avatar
      size={32}
      shape="square"
      className="flex items-center justify-center"
      style={{
        backgroundColor: '#CF142110',
      }}
      src={(
        <img
          src={PointIcon}
          alt="PointIcon"
          style={{
            width: 16,
            height: 16,
            filter:
              'invert(22%) sepia(79%) saturate(7276%) hue-rotate(349deg) brightness(82%) contrast(96%)',
          }}
        />
      )}
    />
    <Typography.Text style={{ color: '#CF1421' }}>
      {record.name}
    </Typography.Text>
  </Space>
);

Columns.menuName = {
  title: '商品名',
  width: 200,
  fixed: 'left',
  render: (record) => {
    if (record.itemType === 'ORDER_ITEM') {
      return renderOrderItemName(record);
    }
    if (record.itemType === 'UPDATE_ITEM') {
      return renderUpdateItemName(record);
    }
    if (record.itemType === 'DISCOUNT_POINT_ITEM') {
      return renderPointUsageItemName(record);
    }
    return null;
  },
};

Columns.price = {
  title: '単価(税抜)',
  width: 100,
  align: 'right',
  key: 'price',
};

Columns.quantity = {
  title: '数量',
  width: 70,
  align: 'right',
  key: 'quantity',
};

Columns.taxRate = {
  title: '税率',
  width: 70,
  key: 'taxRate',
  render: (record) => record.itemType === 'ORDER_ITEM' && `${record.taxRate * 100}%`,
};

Columns.totalPriceAfterTax = {
  title: '小計(税込)',
  width: 100,
  align: 'right',
  key: 'totalPriceAfterTax',
  fixed: 'right',
  render: (record) => {
    const isReturnedMenu = record.itemType === 'UPDATE_ITEM' && record.type === 'RETURNED_MENU';
    const recordValue = record.itemType === 'ORDER_ITEM'
      ? record.totalPriceAfterTax
      : record.value;
    return (
      <div style={isReturnedMenu ? { color: '#cf1421' } : {}}>
        {`${recordValue < 0 ? '-' : ''}¥${Helpers.numberWithCommas(
          Math.abs(recordValue)
        )}`}
      </div>
    );
  },
};

Columns.brand = {
  title: 'ブランド',
  dataIndex: 'brand',
  width: 250,
  key: 'brand',
  render: (record) => !_isEmpty(record) && (
    <div className="flex items-center full-w">
      <Avatar
        alt="brand"
        src={_get(record, 'image', DefaultBrandImage)}
        size={32}
      />
      <Space
        direction="vertical"
        size={0}
        style={{ overflow: 'hidden', width: '75%', marginLeft: 8 }}
      >
        <Tooltip placement="topLeft" title={_get(record, 'name')}>
          <Typography.Text ellipsis>{_get(record, 'name')}</Typography.Text>
        </Tooltip>
        <Tooltip placement="topLeft" title={_get(record, 'email')}>
          <Typography.Text ellipsis>{_get(record, 'email')}</Typography.Text>
        </Tooltip>
      </Space>
    </div>
  ),
};

Columns.totalBrandFee = {
  title: '合計ブランドフィー',
  align: 'right',
  dataIndex: 'totalBrandFee',
  width: 160,
  render: (record) => <div>¥{Helpers.numberWithCommas(Math.abs(record))}</div>,
};

export default Columns;
