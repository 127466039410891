/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment-timezone';
import {
  Descriptions,
  Card,
  Col,
  Row,
  Space,
  Typography,
  Divider,
  Button,
} from 'antd';
import { push } from 'connected-react-router';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import { DATE_TIME_FORMAT } from 'utils/constants';
import mfKessai from 'images/mf-kessai.svg';
import { doRefresh } from 'providers/CommonProvider/actions';
import EmailInvitation from 'components/EmailInvitation';
import Helpers from 'utils/helpers';
import StatusToggle from '../../Operator_List/StatusToggle';
import ProfileInformation from './ProfileInformation';
import MFSetting from './MFSetting';
import renderMembershipInformationItem from './MembershipInformation';
import OriginalRecipeConsultationSupportField from './OriginalRecipeConsultationSupportField';
import RestaurantPointInfo from './RestaurantPointInfo';

moment.tz.setDefault('Asia/Tokyo');

const { Text } = Typography;
const { Item } = Descriptions;

const ORIGINAL_RECIPE_CONSULTATION_STATUS_JP = {
  HOPE: '希望する',
  UNHOPE: '希望しない',
};

const DividerItem = () => (
  <Item>
    <div className="divider">
      <Divider />
    </div>
  </Item>
);

const renderDate = (date, format = DATE_TIME_FORMAT) => (date ? moment(date).format(format) : '-');

const InfoOEM = ({ infoOEM, restaurantDetail }) => (
  <>
    <Divider />
    <Descriptions
      title="オリジナルレシピ　相談希望"
      column={1}
      bordered
      labelStyle={{ verticalAlign: 'top' }}
    >
      <Descriptions.Item label="相談希望">
        {ORIGINAL_RECIPE_CONSULTATION_STATUS_JP[
          infoOEM?.originalRecipeConsultationStatus
        ] || '未回答'}
      </Descriptions.Item>
      <Descriptions.Item label="回答日時">
        {renderDate(infoOEM?.originalRecipeAnswerDate)}
      </Descriptions.Item>
      <Descriptions.Item label="相談対応有無">
        <OriginalRecipeConsultationSupportField
          restaurantDetail={restaurantDetail}
        />
      </Descriptions.Item>
    </Descriptions>
    <Divider />
    <Descriptions
      title="メニュー提案　実施有無"
      column={1}
      bordered
      labelStyle={{ verticalAlign: 'top' }}
    >
      <Descriptions.Item label="提案実施日時">
        {renderDate(infoOEM?.menuSuggestionSurveyDate)}
      </Descriptions.Item>
    </Descriptions>
  </>
);

const RestaurantInfo = () => {
  const dispatch = useDispatch();
  const restaurantDetail = useSelector(
    (state) => state.restaurantProvider.restaurantDetail,
    shallowEqual
  );

  const {
    updatedAt, createdAt, objectId, email, name, user, infoOEM
  } = restaurantDetail;

  const emailVerified = user?.emailVerified;
  const registrationState = user?.registrationState;

  return (
    <Row gutter={[16, 16]}>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Space direction="vertical" size={16} className="full-w flex">
          <ProfileInformation
            restaurantDetail={restaurantDetail}
            showEditAction
          />
          <Card
            className="highlight-card padding-card-16"
            title="マイストア運営費"
            bordered={false}
          >
            <Descriptions column={1} bordered>
              <Item label="マイストア運営費（税込）">
                <Space className="flex justify-between">
                  <Text>
                    {restaurantDetail?.myStoreFee != null
                      ? `¥${Helpers.numberWithCommas(
                        restaurantDetail?.myStoreFee
                      )}`
                      : '—'}
                  </Text>
                  <Button
                    type="ghost"
                    onClick={() => dispatch(
                      push(
                        `/restaurants/detail/${restaurantDetail.formattedObjectId}/my-store-fee`
                      )
                    )}
                  >
                    更新
                  </Button>
                </Space>
              </Item>
            </Descriptions>
          </Card>
        </Space>
      </Col>
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <Space direction="vertical" size={16} className="full-w flex">
          <Card
            className="highlight-card padding-card-16"
            title="アカウント"
            bordered={false}
          >
            <Descriptions
              column={1}
              bordered
              className="restaurant-description"
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Item label="アカウント">
                <EmailInvitation
                  isPrimary
                  restaurantId={objectId}
                  email={email}
                  emailVerified={emailVerified}
                  isHidden={registrationState < 4}
                />
              </Item>
              <DividerItem />
              <Item label="ステータス">
                <StatusToggle
                  rowRecord={{
                    objectId: restaurantDetail.objectId,
                    name: restaurantDetail.name,
                    status: restaurantDetail?.user?.status,
                    callBack: () => {
                      dispatch(doRefresh({ target: 'restaurantDetail' }));
                    },
                  }}
                />
              </Item>
              <DividerItem />
              <Item
                label={<img src={mfKessai} alt="mf-kessai" />}
                className="MF-container"
              >
                <MFSetting
                  status={!restaurantDetail?.isStopMFKessai}
                  restaurantDetail={restaurantDetail}
                />
              </Item>
              <DividerItem />
              {RestaurantPointInfo({
                restaurantId: objectId,
                restaurantFormattedId: restaurantDetail?.formattedObjectId,
              })}
              <DividerItem />
              {renderMembershipInformationItem({
                name: restaurantDetail?.name,
                formattedObjectId: restaurantDetail?.formattedObjectId,
                restaurant_membership: restaurantDetail?.restaurant_membership,
              })}
            </Descriptions>
          </Card>
          <Card title="販売者" className="highlight-card padding-card-16">
            <Descriptions column={1} bordered>
              <Item label="販売者件数">
                <Space className="flex justify-between">
                  <Text>{restaurantDetail?.supplierCount} 販売者</Text>
                  <Button
                    type="ghost"
                    onClick={() => dispatch(
                      push(
                        `/restaurants/detail/${restaurantDetail?.formattedObjectId}/connected-suppliers`
                      )
                    )}
                  >
                    詳細
                  </Button>
                </Space>
              </Item>
            </Descriptions>
          </Card>
          <Card
            title="請求書送付先を追加"
            className="highlight-card padding-card-16"
          >
            <EmailInvitation
              restaurantId={objectId}
              title="請求書送付先を追加"
              name={name}
              isHidden={registrationState < 4}
            />
          </Card>
          <Card
            className="highlight-card padding-card-16"
            title="Activities"
            bordered={false}
          >
            <Descriptions
              title="変更履歴"
              column={1}
              bordered
              labelStyle={{ verticalAlign: 'top' }}
            >
              <Descriptions.Item label="更新日">
                {renderDate(updatedAt)}
              </Descriptions.Item>
              <Descriptions.Item label="作成日">
                {renderDate(createdAt)}
              </Descriptions.Item>
            </Descriptions>
            <InfoOEM infoOEM={infoOEM} restaurantDetail={restaurantDetail} />
          </Card>
        </Space>
      </Col>
    </Row>
  );
};

export default RestaurantInfo;
